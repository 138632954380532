// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ResizableCard_card__ReacC {
  width: 100%;
}

.ResizableCard_resizableCard__x9FKc {
  display: flex;
  overflow: auto;
  flex-direction: row;
}

.ResizableCard_cardHeaderNoWrap__l\\+JlV {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.ResizableCard_textTruncate__jVQcp {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ResizableCard_buttonHeaderCard__C6nHP {
  align-items: center;
  display: flex;
  gap: 0.8rem;
}

.ResizableCard_buttonIconDisabled__IQiii {
  cursor: not-allowed;
  opacity: 0.3;
  margin-right: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/ResizableSection/ResizableCard/ResizableCard.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,8BAA8B;AAChC;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,oBAAoB;AACtB","sourcesContent":[".card {\r\n  width: 100%;\r\n}\r\n\r\n.resizableCard {\r\n  display: flex;\r\n  overflow: auto;\r\n  flex-direction: row;\r\n}\r\n\r\n.cardHeaderNoWrap {\r\n  display: flex;\r\n  flex-wrap: nowrap;\r\n  justify-content: space-between;\r\n}\r\n\r\n.textTruncate {\r\n  white-space: nowrap;\r\n  overflow: hidden;\r\n  text-overflow: ellipsis;\r\n}\r\n\r\n.buttonHeaderCard {\r\n  align-items: center;\r\n  display: flex;\r\n  gap: 0.8rem;\r\n}\r\n\r\n.buttonIconDisabled {\r\n  cursor: not-allowed;\r\n  opacity: 0.3;\r\n  margin-right: 0.5rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `ResizableCard_card__ReacC`,
	"resizableCard": `ResizableCard_resizableCard__x9FKc`,
	"cardHeaderNoWrap": `ResizableCard_cardHeaderNoWrap__l+JlV`,
	"textTruncate": `ResizableCard_textTruncate__jVQcp`,
	"buttonHeaderCard": `ResizableCard_buttonHeaderCard__C6nHP`,
	"buttonIconDisabled": `ResizableCard_buttonIconDisabled__IQiii`
};
export default ___CSS_LOADER_EXPORT___;

// React
import React, { useState } from "react";
// Components
import { ToastQueueProvider } from "../ToastQueueProvider/ToastQueueProvider";
import LoadingButton from "../LoadingButton/LoadingButton";
// React Bootstrap
import { Card, Form, Accordion } from "react-bootstrap";
// Styles
import styles from "./CodeEditor.module.css";
// Fhir Front Library
import {
  FhirStatus,
  StatusAlert,
  StatusIcon,
} from "@fyrstain/fhir-front-library";
// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faXmark,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
// FHIR
import { Parameters, OperationOutcome, Bundle } from "fhir/r5";
import Client from "fhir-kit-client";
// Translation
import i18n from "i18next";

////////////////////////////////
//           Props            //
////////////////////////////////

interface OutputProps {
  // The content of the output
  content: Array<inputs>;
  // The function to update the content
  setContent: any;
  // To disable the "check" button when the user has an error
  isFailingOperation?: boolean;
}

////////////////////////////////
//         Interfaces         //
////////////////////////////////

interface inputs {
  name: string;
  content?: string;
  profile?: string;
  validationReportID?: string;
  validationReportStatus?: string;
}

interface ShowAlertType {
  [key: number]: boolean;
}

const Output: React.FC<OutputProps> = ({
  content,
  setContent,
  isFailingOperation,
}) => {
    
  /////////////////////////////////////
  //      Constants / ValueSet       //
  /////////////////////////////////////

  const [showAlert, setShowAlert] = useState<ShowAlertType>({});

  // Show the spinner when "true" and hide when "false"
  const [isDataLoading, setIsDataLoading] = useState<number | null>();

  // Creating a Toast using the Component Toast
  const { createToast } = ToastQueueProvider.useToastQueue();

  /////////////////////////////////////
  //             Client              //
  /////////////////////////////////////

  const fhirOperationClient = new Client({
    baseUrl: process.env.REACT_APP_VALIDATE_URL ?? "fhir",
  });

  ////////////////////////////////
  //           Actions          //
  ////////////////////////////////

  /**
   * Open the only one alert of the card and update the state
   * @param index ID/position of an alert in the list
   */
  const handleOpenAlert = (index: number) => {
    setShowAlert({
      ...showAlert,
      [index]: true,
    });
  };

  /**
   * Close the alert of the card
   * @param index ID/position of an alert in the list
   */
  const handleCloseAlert = (index: number) => {
    setShowAlert({
      ...showAlert,
      [index]: false,
    });
  };

  /**
   * Capitalize the first letter
   *
   * @param string the string to capitalize
   */
  function capitalizeFirst(string: string): string {
    const splitted = string.split("");
    splitted[0] = splitted[0].toUpperCase();
    return splitted.join("");
  }

  /**
   * Use the operation validate
   * @param input input to validate with data or a profile
   * @param index index to open only one Alert
   */
  async function validate(input: inputs, index: any) {
    handleOpenAlert(index);
    setIsDataLoading(index);
    let parameter: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "validatorId",
          valueString: "09c3f5d9-98a3-4147-b40c-1e58ecc54fab",
        },
        {
          name: "resource",
          valueString: input.content,
        },
      ],
    };
    if (input.profile) {
      parameter.parameter?.push({
        name: "profile",
        valueCanonical: input.profile,
      });
    }
    await fhirOperationClient
      .operation({
        name: "validate",
        input: parameter,
      })
      .then((response) => {
        parseValidateResponse(response, input);
      })
      .catch((error) => {
        setIsDataLoading(null);
        // A toast when an error occured
        createToast({
          title: i18n.t("text.errorvalidateheader"),
          icon: faTriangleExclamation,
          body: error.response
            ? JSON.stringify(
                error.response.data.issue[0].diagnostics,
                null,
                "\t"
              )
            : i18n.t("text.errorvalidatetext"),
        });
      });
    let newcontent = [...content];
    setContent(newcontent);
    setIsDataLoading(null);
  }

  /**
   * Preventing the accordion to be opened or closed when clicking on the check button
   * @param entry  the entry to validate
   * @param index the index of the entry
   */
  const handleCheckClick = (entry: inputs, index: number) => {
    return (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      validate(entry, index);
    };
  };

  /**
   * Parse the validation response and update the input with the validation report details
   * @param response the response received from the FHIR validate operation
   * @param input the input object to update with the validation report details
   */
  function parseValidateResponse(response: Bundle, input: inputs) {
    let validationStatus: any = "";
    input.validationReportID = response.id;
    let responseFiltered = response.entry?.filter(
      (l) => l.resource?.resourceType === "OperationOutcome"
    )[0].resource as OperationOutcome;
    if (
      responseFiltered &&
      responseFiltered.issue[0] &&
      responseFiltered.issue[0].details &&
      responseFiltered.issue[0].details.coding
    ) {
      validationStatus = responseFiltered.issue[0].details.coding[0].code;
      input.validationReportStatus = validationStatus ? validationStatus : "";
    }
  }

  //////////////////////////////
  //          Content         //
  //////////////////////////////

  return (
    <div className="accordionCard">
      <Form.Group className="col">
        {content.length == 0 && (
          <Card.Body className={styles.cardOutputPadding}>
            <Form.Control
              as="textarea"
              rows={22}
              name="output"
              readOnly={true}
              value={i18n.t("text.outputtext")}
              onChange={(value: any) => setContent(value)}
              className={styles.textAreaOutput}
            />
          </Card.Body>
        )}
        {content.map((entry, index) => (
          <>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  {!isFailingOperation && (
                    <LoadingButton
                      isLoading={isDataLoading === index}
                      onClick={handleCheckClick(entry, index)}
                      enabledTooltipText={i18n.t("tooltip.check")}
                      icon={faCircleCheck}
                      isOutput={true}
                    />
                  )}
                  <div className="textTruncate">{entry.name.toUpperCase()}</div>
                </Accordion.Header>
                <Accordion.Body>
                  {showAlert[index] && (
                    <StatusAlert
                      status={
                        FhirStatus[
                          (entry.validationReportStatus ||
                            "unknown") as keyof typeof FhirStatus
                        ]
                      }
                    >
                      <div className={styles.alertContainer}>
                        <div>
                          <StatusIcon
                            status={
                              FhirStatus[
                                (entry.validationReportStatus ||
                                  "unknown") as keyof typeof FhirStatus
                              ]
                            }
                          />
                          <strong className={styles.alertTitle}>
                            {`${capitalizeFirst(
                              entry.validationReportStatus || "Unknown error"
                            )} validation`}
                          </strong>
                          <div>
                            <a
                              href={`https://demo.fyrstain.com/ValidationReport/${entry.validationReportID}`}
                              target="_blank"
                              className={styles.link}
                            >
                              {entry.validationReportID
                                ? i18n.t("text.validationreport")
                                : ""}
                            </a>
                          </div>
                        </div>
                        <div className={styles.xmarkEnd}>
                          <FontAwesomeIcon
                            icon={faXmark}
                            onClick={() => handleCloseAlert(index)}
                            className="actionIcon"
                          />
                        </div>
                      </div>
                    </StatusAlert>
                  )}
                  <Form.Control
                    as="textarea"
                    rows={22}
                    name={"output-" + index}
                    readOnly={true}
                    value={
                      entry.content ? entry.content : i18n.t("text.outputtext")
                    }
                    onChange={(value: any) => setContent(value)}
                    className={styles.textAreaOutput}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </>
        ))}
      </Form.Group>
    </div>
  );
};

export default Output;

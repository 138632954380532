// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ResizableContainer_resizableContainer__AWxY\\+ {
  display: flex;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/components/ResizableSection/ResizableContainer.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;AAClB","sourcesContent":[".resizableContainer {\r\n  display: flex;\r\n  overflow: hidden;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resizableContainer": `ResizableContainer_resizableContainer__AWxY+`
};
export default ___CSS_LOADER_EXPORT___;

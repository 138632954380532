// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;utf8,<svg xmlns=%27http://www.w3.org/2000/svg%27 width=%2710%27 height=%2730%27><path d=%27M2 0 v30 M5 0 v30 M8 0 v30%27 fill=%27none%27 stroke=%27black%27/></svg>", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ResizerBar_noSelectWhenResizing__eqCGZ {
  -webkit-user-select: none;
          user-select: none;
}

.ResizerBar_resizerBar__y-EdO {
  position: relative;
  right: 0;
  top: 0;
  width: 0.5rem;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  cursor: ew-resize;
  background-color: rgba(0, 0, 0, 0.1);
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.ResizerBar_resizerBar__y-EdO:active {
  background-color: rgba(255, 0, 0, 0.2);
}`, "",{"version":3,"sources":["webpack://./src/components/ResizableSection/ResizerBar/ResizerBar.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAiB;UAAjB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,MAAM;EACN,aAAa;EACb,kBAAkB;EAClB,qBAAqB;EACrB,iBAAiB;EACjB,oCAAoC;EACpC,4BAA4B;EAC5B,2BAA2B;EAC3B,yDAAuL;AACzL;;AAEA;EACE,sCAAsC;AACxC","sourcesContent":[".noSelectWhenResizing {\r\n  user-select: none;\r\n}\r\n\r\n.resizerBar {\r\n  position: relative;\r\n  right: 0;\r\n  top: 0;\r\n  width: 0.5rem;\r\n  margin-top: 0.2rem;\r\n  margin-bottom: 0.2rem;\r\n  cursor: ew-resize;\r\n  background-color: rgba(0, 0, 0, 0.1);\r\n  background-repeat: no-repeat;\r\n  background-position: center;\r\n  background-image: url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='30'><path d='M2 0 v30 M5 0 v30 M8 0 v30' fill='none' stroke='black'/></svg>\");\r\n}\r\n\r\n.resizerBar:active {\r\n  background-color: rgba(255, 0, 0, 0.2);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noSelectWhenResizing": `ResizerBar_noSelectWhenResizing__eqCGZ`,
	"resizerBar": `ResizerBar_resizerBar__y-EdO`
};
export default ___CSS_LOADER_EXPORT___;

// React
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
// Components
import JanusTestablePage from "../../components/JanusTestablePage/JanusTestablePage";
import CodeEditor from "../../components/CodeEditor/CodeEditor";
import Output from "../../components/CodeEditor/Output";
import { ToastQueueProvider } from "../../components/ToastQueueProvider/ToastQueueProvider";
import ResizableContainer from "../../components/ResizableSection/ResizableContainer";
// Fhir Front library
import { FhirStatus, StatusTag, Title, QuestionnaireDisplay, ValueSetLoader } from "@fyrstain/fhir-front-library";
// FHIR
import Client from "fhir-kit-client";
// Resources
import {
  Questionnaire,
  Parameters,
  QuestionnaireResponse,
  Bundle,
} from "fhir/r5";
// Translation
import i18n from "i18next";
// React Bootstrap
import { Button, Card, Form } from "react-bootstrap";
// Navigation
import { useNavigate, useParams } from "react-router-dom";
// Styles
import styles from "./QuestionnaireDetails.module.css";
// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faRotateRight,
  faPlay,
  faGear,
  faEye,
  faEyeSlash,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";

////////////////////////////////
//         Interfaces         //
////////////////////////////////

interface inputs {
  name: string;
  type: string;
  mode?: string;
  content?: string;
  profile?: string;
}

const QuestionnaireDetail: FunctionComponent = () => {
    
  /////////////////////////////////////
  //      Constants / ValueSet       //
  /////////////////////////////////////

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // Questionnaire informations
  const { questionnaireId } = useParams();
  const [name, setName] = useState("");
  const [status, setStatus] = useState("");
  const [description, setDescription] = useState("");
  const [responseFormat, setResponseFormat] = useState("JSON");

  // Contents & Resources
  const [questionnaireContent, setQuestionnaireContent] = useState("");
  const [questionnaireResource, setQuestionnaireResource] = useState(
    {} as Questionnaire
  );

  const [questionnaireForExtract, setQuestionnaireForExtract] = useState({} as Questionnaire);

  const [initialQuestionnaireContent, setInitialQuestionnaireContent] =
    useState("");
  const [questionnaireResponseContent, setQuestionnaireResponseContent] =
    useState("");
  const [outputContent, setOutputContent] = useState(new Array<inputs>());

  // To enable or disabled the "play" button to que QuestionnaireResponse field when the user has used the populate operation
  const [isPopulated, setIsPopulated] = useState(true);

  // Show the spinner when "true" and hide when "false"
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isResetQuestionnaireLoading, setIsResetQuestionnaireLoading] =
    useState(false);
  const [isPopulateLoading, setIsPopulateLoading] = useState(false);
  const [isExtractLoading, setIsExtractLoading] = useState(false);
  const [isResetOutputLoading, setIsResetOutputLoading] = useState(false);

  // To disable the "check" button to the Output field when the user has an error
  const [isFailingOperation, setIsfailingOperation] = useState(false);

  // To show or hide the content/card with the buttonToggle
  const [showQuestionnaire, setShowQuestionnaire] = useState(true);
  const [showQuestionnaireResponse, setShowQuestionnaireResponse] =
    useState(true);
  const [showOutput, setShowOutput] = useState(true);

  // To resize the cards
  const [questionnaireFlex, setQuestionnaireFlex] = useState({ flex: 1 });
  const [questionnaireResponseFlex, setQuestionnaireResponseFlex] = useState({
    flex: 1,
  });
  const [outputFlex, setOutputFlex] = useState({ flex: 1 });
  const containerWidthRef = useRef<HTMLDivElement | null>(null);
  const questionnaireRef = useRef<HTMLDivElement | null>(null);
  const questionnaireResponseRef = useRef<HTMLDivElement | null>(null);
  const outputRef = useRef<HTMLDivElement | null>(null);

  // Creating a Toast using the Component Toast
  const { createToast } = ToastQueueProvider.useToastQueue();

  /////////////////////////////////////
  //             Client              //
  /////////////////////////////////////

  const fhirClient = new Client({
    baseUrl: process.env.REACT_APP_FHIR_URL ?? "fhir",
  });

  const fhirOperationClient = new Client({
    baseUrl: process.env.REACT_APP_QUESTIONNAIRE_URL ?? "fhir",
  });

  //////////////////////////////
  //           Error          //
  //////////////////////////////

  const onError = useCallback(() => {
    navigate("/Error");
  }, [navigate]);

  ////////////////////////////////
  //           Actions          //
  ////////////////////////////////

  useEffect(() => {
    load();
  }, []);

  /**
   * To load the Questionnaire and switch the state of setLoading
   */
  async function load() {
    setLoading(true);
    await loadQuestionnaire();
    setLoading(false);
  }

  /**
   * Load Questionnaire from the back to populate the fields.
   *
   * @returns the promise of a Questionnaire.
   */
  async function loadQuestionnaire() {
    try {
      const response = await fhirClient.read({
        resourceType: "Questionnaire",
        id: questionnaireId ?? "",
      });
      if (response.resourceType !== "Questionnaire") {
        throw Error(response.statusText);
      }
      const questionnaire: Questionnaire = response as Questionnaire;
      const stringifiedQuestionnaire = JSON.stringify(
        questionnaire,
        null,
        "\t"
      );
      setName(questionnaire.title ? questionnaire.title : "N/A");
      setDescription(
        questionnaire.description ? questionnaire.description : "N/A"
      );
      setStatus(questionnaire.status ? questionnaire.status : "N/A");
      setQuestionnaireContent(stringifiedQuestionnaire);
      setQuestionnaireResource(questionnaire);
      setInitialQuestionnaireContent(stringifiedQuestionnaire);
    } catch (error) {
      onError();
    }
  }

  //Functions to show or hide the cards with a ToggleButton
  function toggleQuestionnaire() {
    setShowQuestionnaire(!showQuestionnaire);
  }
  function toggleQuestionnaireResponse() {
    setShowQuestionnaireResponse(!showQuestionnaireResponse);
  }
  function toggleOutput() {
    setShowOutput(!showOutput);
  }

  /**
   * Function to adjust size/flex according to toggle buttons
   */
  const adjustCardSizes = () => {
    const visibleCards = [
      showQuestionnaire,
      showQuestionnaireResponse,
      showOutput,
    ].filter(Boolean).length;
    // To have the same size for each card when all are visible or adjust the size when one or two are hidden
    const equalFlex = 1 / visibleCards;
    setQuestionnaireFlex({ flex: showQuestionnaire ? equalFlex : 0 });
    setQuestionnaireResponseFlex({
      flex: showQuestionnaireResponse ? equalFlex : 0,
    });
    setOutputFlex({ flex: showOutput ? equalFlex : 0 });
  };

  /**
   * To ajust the size/flex cards
   */
  useEffect(() => {
    adjustCardSizes();
  }, [showQuestionnaire, showQuestionnaireResponse, showOutput]);

  /**
   * Function to stringify the response of the questionnaire in JSON
   * @param response Response of the questionnaire
   * @returns a stringify response
   */
  function parseResponse(response: QuestionnaireResponse) {
    setQuestionnaireResponseContent(JSON.stringify(response, null, "\t"));
  }

  /**
   * Function to stringify the response of the extract in JSON
   * @param response Response of the extract
   * @returns a stringify response
   */
  function parseExtractResponse(response: Bundle) {
    let inputs = new Array<inputs>();
    response.entry?.map((entry) => {
      inputs.push({
        name: entry.resource?.id ? entry.resource?.id : "",
        type: entry.resource?.resourceType ? entry.resource?.resourceType : "",
        content: JSON.stringify(entry.resource, null, "\t"),
      });
    });
    // Update the state with the formated data
    setOutputContent(inputs);
  }

  function getQuestionnaireAsResource(): Questionnaire {
    return JSON.parse(questionnaireContent) as Questionnaire;
  }

  function getQuestionnaireResponseAsResource(): QuestionnaireResponse {
    return JSON.parse(questionnaireResponseContent) as QuestionnaireResponse;
  }

  function viewEnabled(): boolean {
    if (!questionnaireResponseContent) {
      return false;
    } else {
      try {
        getQuestionnaireResponseAsResource();
      } catch (error) {
        return false;
      }
      return true;
    }
  }

  /**
   * Save the content of the questionnaire
   */
  async function onSave() {
    setIsSaveLoading(true);
    try {
      await fhirClient.update({
        resourceType: "Questionnaire",
        id: questionnaireId ?? "",
        body: JSON.parse(questionnaireContent),
      });
    } catch (error) {
      onError();
      setIsSaveLoading(false);
    }
    setIsSaveLoading(false);
  }

  /**
   * Reset the Questionnaire's content, returning the initial content
   */
  const resetQuestionnaireContent = async () => {
    setIsResetQuestionnaireLoading(true);
    //setTimeout is used to show the loading spinner
    setTimeout(() => {
      setQuestionnaireContent(initialQuestionnaireContent);
      setIsResetQuestionnaireLoading(false);
    }, 200);
  };

  /**
   * Function to use the operation Populate for a questionnaire
   */
  async function onPopulate() {
    setIsPopulateLoading(true);
    // Use the parameter questionnaire
    let parameter: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "questionnaire",
          resource: getQuestionnaireAsResource(),
        },
      ],
    };
    await fhirOperationClient
      .operation({
        name: "populate",
        input: parameter,
        resourceType: "Questionnaire",
      })
      .then((response) => {
        parseResponse(response);
        setIsPopulated(false);
        setIsfailingOperation(false);
      })
      .catch((error) => {
        setIsPopulateLoading(false);
        setIsfailingOperation(true);
        // See errors in the code editor (Output)
        setOutputContent([
          {
            name: "error",
            type: "Error",
            content: error.response?.data
              ? JSON.stringify(error.response?.data, null, "\t")
              : error.toString(),
          },
        ]);
        // A toast when an error occured
        createToast({
          title: i18n.t("text.errorvalidateheader"),
          icon: faTriangleExclamation,
          body: error.response
            ? JSON.stringify(
                error.response.data.issue[0].diagnostics,
                null,
                "\t"
              )
            : i18n.t("text.errorvalidatetext"),
        });
      });
    setQuestionnaireForExtract(getQuestionnaireAsResource());
    setIsPopulateLoading(false);
  }

  /**
   * Function using the operation Extract to extract the data from a questionnaire response
   */
  async function onExtract() {
    setIsExtractLoading(true);
    // Use the parameter questionnaire-response
    let parameter: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "questionnaire-response",
          resource: getQuestionnaireResponseAsResource(),
        },
      ],
    };
    await fhirOperationClient
      .operation({
        name: "extract",
        input: parameter,
        resourceType: "QuestionnaireResponse",
      })
      .then((response) => {
        parseExtractResponse(response);
        setIsfailingOperation(false);
      })
      .catch((error) => {
        setIsExtractLoading(false);
        setIsfailingOperation(true);
        // See errors in the Output field
        setOutputContent([
          {
            name: "error",
            type: "Error",
            content: error.response?.data
              ? JSON.stringify(error.response?.data, null, "\t")
              : error.toString(),
          },
        ]);
        // A toast when an error occured
        createToast({
          title: i18n.t("text.errorvalidateheader"),
          icon: faTriangleExclamation,
          body: error.response
            ? JSON.stringify(
                error.response.data.issue[0].diagnostics,
                null,
                "\t"
              )
            : i18n.t("text.errorvalidatetext"),
        });
      });
    setIsExtractLoading(false);
  }
  /**
   * Function to reset the output content, returning the initial content without the fields
   */
  const resetOutputContent = () => {
    setIsResetOutputLoading(true);
    setTimeout(() => {
      setOutputContent([]);
      setIsResetOutputLoading(false);
    }, 200);
  };

  //////////////////////////////
  //          Content         //
  //////////////////////////////

  return (
    <JanusTestablePage
      titleKey="Questionnaire"
      loading={loading}
      needsLogin={true}
      urlTestable={questionnaireResource.url}
      testUrl={process.env.REACT_APP_TEST_URL}
      testServerUrl={process.env.REACT_APP_TEST_SERVER_URL}
      serverId={process.env.REACT_APP_R4_SERVER_ID}
      clientId={process.env.REACT_APP_R4_CLIENT_ID}
    >
      <>
        {/* INFORMATION */}
        <div className="testAccordionSection">
          <div className="section testAccordionSection">
            <Card className={styles.card}>
              <Card.Header>
                <Title level={2} content="Informations" />
              </Card.Header>

              <Card.Body className="flexWrap">
                <div className={styles.form}>
                  <div className={styles.formTextLabel}>
                    <Form.Label className={styles.formLabel}>
                      <strong className={styles.label}>ID :</strong>
                    </Form.Label>
                    <Form.Text>{questionnaireId}</Form.Text>
                  </div>

                  <div className={styles.formTextLabel}>
                    <Form.Label className={styles.formLabel}>
                      <strong className={styles.label}>
                        {i18n.t("label.name")} :
                      </strong>
                    </Form.Label>
                    <Form.Text>{name}</Form.Text>
                  </div>

                  <div
                    className={[styles.badgeContainer, "flexWrap"].join(" ")}
                  >
                    <Form.Label className={styles.statuslabel}>
                      <strong>{i18n.t("label.status")} :</strong>
                    </Form.Label>
                    <Form.Text
                      className={[styles.formText, styles.tagMargin].join(" ")}
                    >
                      <StatusTag
                        status={FhirStatus[status as keyof typeof FhirStatus]}
                        statusMessage={status}
                      />
                    </Form.Text>
                  </div>

                  <div className={styles.formTextLabel}>
                    <Form.Label className={styles.formLabel}>
                      <strong className={styles.label}>
                        {i18n.t("label.generaldescription")} :
                      </strong>
                    </Form.Label>
                    <Form.Text>{description}</Form.Text>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>

          <div className="section">
            {/* BUTTONS TOGGLE - SHOW OR HIDE CARDS*/}
            <div className="d-flex mb-3 justify-content-center">
              <Button
                variant={showQuestionnaire ? "primary" : "outline-primary"}
                className={`${"toggleButton"} ${
                  showQuestionnaire
                    ? styles.noHoverPrimary
                    : styles.noHoverOutlinePrimary
                }`}
                onClick={toggleQuestionnaire}
              >
                <FontAwesomeIcon
                  icon={showQuestionnaire ? faEye : faEyeSlash}
                  className="me-3"
                />
                Questionnaire
              </Button>
              <Button
                variant={
                  showQuestionnaireResponse ? "primary" : "outline-primary"
                }
                className={`${"me-3 ms-3 toggleButton"} ${
                  showQuestionnaireResponse
                    ? styles.noHoverPrimary
                    : styles.noHoverOutlinePrimary
                }`}
                onClick={toggleQuestionnaireResponse}
              >
                <FontAwesomeIcon
                  icon={showQuestionnaireResponse ? faEye : faEyeSlash}
                  className="me-3"
                />
                {i18n.t("text.questionnaireresponse")}
              </Button>
              <Button
                variant={showOutput ? "primary" : "outline-primary"}
                className={`${"toggleButton"} ${
                  showOutput
                    ? styles.noHoverPrimary
                    : styles.noHoverOutlinePrimary
                }`}
                onClick={toggleOutput}
              >
                <FontAwesomeIcon
                  icon={showOutput ? faEye : faEyeSlash}
                  className="me-3"
                />
                Outputs
              </Button>
            </div>

            <ResizableContainer
              containerWidthRef={containerWidthRef}
              cardConfig={[
                {
                  cardFlex: questionnaireFlex,
                  cardRef: questionnaireRef,
                  cardTitle: "Questionnaire",
                  loadingButtonConfig: [
                    {
                      isLoading: isSaveLoading,
                      icon: faSave,
                      onClick: onSave,
                      enabledTooltipText: i18n.t("tooltip.save"),
                    },
                    {
                      icon: faGear,
                      isDisabled: true,
                      disabledTooltipText: i18n.t("tooltip.disabled"),
                    },
                    {
                      isLoading: isResetQuestionnaireLoading,
                      icon: faRotateRight,
                      onClick: resetQuestionnaireContent,
                      enabledTooltipText: i18n.t("tooltip.reset"),
                    },
                    {
                      isLoading: isPopulateLoading,
                      icon: faPlay,
                      onClick: onPopulate,
                      enabledTooltipText: i18n.t("tooltip.play"),
                      operationName: "$populate",
                    },
                  ],
                  showResizerBar: showQuestionnaireResponse || showOutput,
                  resizerBarConfig: {
                    containerWidthRef,
                    leftRef: questionnaireRef,
                    rightRef: showQuestionnaireResponse
                      ? questionnaireResponseRef
                      : outputRef,
                    setLeftFlex: setQuestionnaireFlex,
                    setRightFlex: showQuestionnaireResponse
                      ? setQuestionnaireResponseFlex
                      : setOutputFlex,
                  },
                  cardBodyChildren: (
                    <CodeEditor
                      content={questionnaireContent}
                      setContent={setQuestionnaireContent}
                    />
                  ),
                },
                {
                  cardFlex: questionnaireResponseFlex,
                  cardRef: questionnaireResponseRef,
                  cardTitle: i18n.t("text.questionnaireresponse"),
                  loadingButtonConfig: [
                    {
                      isLoading: isExtractLoading,
                      icon: faPlay,
                      onClick: onExtract,
                      enabledTooltipText: i18n.t("tooltip.play"),
                      isDisabled: isPopulated,
                      disabledTooltipText: i18n.t(
                        "tooltip.disabledplayquestionnaire"
                      ),
                      operationName: "$extract",
                    },
                  ],
                  showResizerBar: showOutput,
                  resizerBarConfig: {
                    containerWidthRef,
                    leftRef: questionnaireResponseRef,
                    rightRef: outputRef,
                    setLeftFlex: setQuestionnaireResponseFlex,
                    setRightFlex: setOutputFlex,
                  },
                  cardBodyChildren: (
                    <>
                      <Form.Select className="select"
                                      value={ responseFormat }
                                      onChange={ e => setResponseFormat(e.target.value) }
                                  >
                                      <option value="JSON">
                                          JSON
                                      </option>
                                      <option disabled={ !viewEnabled() } value="VIEW">
                                          View
                                      </option>
                                  </Form.Select>
                      
                      { responseFormat === "JSON" && 
                        <CodeEditor
                          content={questionnaireResponseContent}
                          setContent={(value: any) =>
                            setQuestionnaireResponseContent(value)
                          }
                        />
                      }
                      { responseFormat === "VIEW" &&
                        <QuestionnaireDisplay
                          language={ i18n.t }
                          questionnaire={ questionnaireForExtract }
                          questionnaireResponse={getQuestionnaireResponseAsResource()}
                          //See if we want to configure that
                          valueSetLoader={ new ValueSetLoader(fhirClient) }
                          submitButtonLabel="Validate answers"
                          onSubmit={(response: QuestionnaireResponse) => {
                            console.log(JSON.stringify(response));
                            setQuestionnaireResponseContent(JSON.stringify(response, null, 4))
                          }}
                          onError={() => { }}
                        />
                      }
                    </>
                  ),
                },
                {
                  cardFlex: outputFlex,
                  cardRef: outputRef,
                  cardTitle: "Outputs",
                  loadingButtonConfig: [
                    {
                      isLoading: isResetOutputLoading,
                      icon: faRotateRight,
                      onClick: resetOutputContent,
                      enabledTooltipText: i18n.t("tooltip.reset"),
                    },
                  ],
                  cardBodyChildren: (
                    <Output
                      content={outputContent}
                      setContent={setOutputContent}
                      isFailingOperation={isFailingOperation}
                    />
                  ),
                },
              ]}
            />
          </div>
        </div>
      </>
    </JanusTestablePage>
  );
};

export default QuestionnaireDetail;
